import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const linkStyle = {
  textAlign: `center`,
  color: "White",
  textDecoration: `none`,
  margin: `auto`,
  padding: `1rem`,
  display: `grid`,
  borderRadius: `16px`,
  boxShadow: `-5px -5px 10px #006666, 5px 5px 10px #003333`,
  textShadow: `0px 0px 5px #000000`,
  fontWeight: `bold`,
}

const ListLink = props => (
  <li
    style={{
      flexGrow: 1,
      textAlign: `center`,
      margin: `1rem`,
    }}
  >
    <Link
      to={props.to}
      style={linkStyle}
    >
      {props.children}
    </Link>
  </li>
)

const OffSiteLink = props => (
  <li
    style={{
      flexGrow: 1,
      textAlign: `center`,
      margin: `1rem`,
    }}
  >
    <a
      href={props.to}
      style={linkStyle}
    >
      {props.children}
    </a>
  </li>
)

const HeroLink = ({siteTitle}) => {
  return (
    <h1
      style={{
        textAlign: `center`,
        margin: `1rem`,
        padding: `1rem 1rem`,
        width: "300px",
        height: "100px",
        alignItems: `center`,
        display: `grid`,
        borderRadius: `32px`,
        boxShadow: `-5px -5px 10px #006666, 5px 5px 10px #003333`,
      }}
    >
      <Link
        to="/"
        style={{
          textDecoration: `none`,
          textAlign: `center`,
          alignItems: `center`,
          textShadow: `0px 0px 5px #000000`,
        }}
      >
        {siteTitle}
      </Link>
    </h1>
  );
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#004040`,
      alignItems: `center`,
    }}
  >
    <div
      style={{
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-around`,
        flexWrap: `wrap`,
        alignItems: `center`,
        color: `white`,
        margin: `auto`,
        maxWidth: `1000px`,
        padding: `1rem`,
      }}
    >
      <HeroLink siteTitle={siteTitle} />
      <nav
        style={{
          flexGrow: 2,
          justifyContent: `space-evenly`,
          alignItems: `center`,
        }}
      >
        <ul
          style={{
            display: `flex`,
            flexDirection: `row`,
            listStyle: `none`,
            justifyContent: `space-evenly`,
            alignItems: `center`,
            height: "100px",
            margin: `auto`,
          }}
        >
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/blog/">Blog</ListLink>
          <OffSiteLink to="https://photos.djmetzle.io/">Photos</OffSiteLink>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
