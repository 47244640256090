import React from "react"

import { FaRegEnvelope, FaLinkedinIn, FaGithub } from 'react-icons/fa'

const GatsbyLink = () => {
  return (
    <div
      style={{
        padding: `1rem`,
        margin: `auto`,
      }}
     >
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </div>
  )
}

const ContactLinks = () => {
  const emailLink = `mailto:me@djmetzle.io`
  const linkedinLink = `https://www.linkedin.com/in/djmetzle/`
  const githubLink = `https://www.github.com/djmetzle`

  return (
    <div
      style={{
        padding: `1rem`,
        margin: `auto`,
      }}
     >
      <a
        href={emailLink}
        style={{
          padding: `1rem`,
        }}
      >
        <FaRegEnvelope />
      </a>
      <a
        href={linkedinLink}
        style={{
          padding: `1rem`,
        }}
      >
        <FaLinkedinIn />
      </a>
      <a
        href={githubLink}
        style={{
          padding: `1rem`,
        }}
      >
        <FaGithub />
      </a>
    </div>
  )
}

const Footer = (props) => {
  return (
    <footer
      style={{
        display: `flex`,
        flexDirection: `row`,
        textAlign: `center`,
        justifyContent: `space-between`,
        margin: `auto`,
        maxWidth: `1000px`,
        paddingBottom: `2rem`,
      }}
     >
      <GatsbyLink />
      <ContactLinks />
    </footer>
  )
}

export default Footer
